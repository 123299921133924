export default {
  name: "forgotPassword",
  data() {
    return {
      email: "",
      error: ""
    };
  },
  methods: {
    forgotPassword() {
      this.$store.dispatch("auth/forgotPassword", {
        email: this.email
      });
    }
  }
};
